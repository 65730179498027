import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer, GeoJSON, Popup, FeatureGroup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { Circle } from 'leaflet';
import * as ra from 'react-admin';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

const useStyles = makeStyles(theme => ({
  map: {
    height: '645px'
  }
}));

const Map = props => {
  const classes = useStyles(props);
  const { data } = ra.useListContext();

  const [map, setMap] = React.useState(null);
  const groupRef = React.useRef();

  React.useEffect(() => {
    if (!map || !groupRef.current) return;
    try {
      map.fitBounds(groupRef.current.getBounds(), { padding: [-50, -50]});
    
    } catch (err) {
      console.error(err);
    }
  }, [map, groupRef, data]);

  return (
    <MapContainer
      className={classes.map}
      center={[34, 17]}
      zoom={2}
      whenCreated={setMap}
      >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <FeatureGroup ref={groupRef} key={Date.now()}>
        {Object.values(data).map(geofence => {
          const { id, name, colorHex, geoJson } = geofence;

          return (
            <GeoJSON
              key={id}
              data={geoJson}
              style={{ color: colorHex }}
              pointToLayer={(feature, latlng) => {
                return new Circle(latlng, feature.properties.radius);
              }}
              >
              <Popup>
                {name}<br/>
                <Link to={ra.linkToRecord('/geofences', id, 'edit')}>
                  Edit
                </Link>
              </Popup>
            </GeoJSON>
          );
        })}
      </FeatureGroup>
    </MapContainer>
  );
};

export default Map