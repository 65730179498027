import React from 'react';
import * as ra from 'react-admin';

import { LoloList } from '@lolocompany/react-admin-lolo';
import { Chip } from '@material-ui/core';

const ExpandPanel = ({ id, record, resource }) => (
  <pre style={{ fontSize: '1.1rem' }}>
    {JSON.stringify(record, null, 2)}
  </pre>
);

const runtimeId = 'u4edf9J5xkiQfD6erLP4iC'; // eu-1.lolo.co

const ListApps = props => (
  <LoloList
    {...props}
    loading={ra.Loading}
    title='Apps'
    >
    <ra.Datagrid expand={ExpandPanel} rowClick='edit'>
      <ra.TextField source='name' />
      <ra.TextField source='description'/>
      <ra.ReferenceManyField
        label='Status'
        reference='deployments' 
        target='appId'
        filter={{runtimeId}}
        perPage={1}
        >
        <StatusChip />
      </ra.ReferenceManyField>
      <ra.TextField source='createdBy' />
      <ra.DateField showTime source='createdAt' />
    </ra.Datagrid>
  </LoloList>
);

const StatusChip = props => {
  const { data } = ra.useListContext(props);
  const [ deployment ] = Object.values(data);

  const label = deployment && deployment.replicas ? 
    'Running' : 
    'Stopped';

  return <Chip size='small' label={label} />
}

export default ListApps;