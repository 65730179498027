import React from 'react';

const hidden = () => <div/>

const create = schema => {
  return {
    ...schema,
    uiSchema: {
      nodes: {
        'ui:field': hidden
      },
      edges: {
        'ui:field': hidden
      }
    },
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      },
      description: {
        type: 'string',
        title: 'Description'
      },
      nodes: {
        type: 'array',
        items: { type: 'object' },
        default: []
      },
      edges: {
        type: 'array',
        items: { type: 'object' },
        default: []
      }
    },
  };
};

export default {
  create
};
