import * as React from 'react';
import { Card, Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 52,
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  main: (props) => ({
    overflow: 'inherit',
    padding: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  title: {},
}));

const FlatCard = props => {
  const { icon, title, subtitle, to, children } = props;
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <Link to={to}>
        <div className={classes.main}>
          <Box textAlign="center">
            <Typography variant="h4" component="h2" color="textSecondary">
              {title || ' '}
            </Typography>
            <Typography
              className={classes.title}
              color="textSecondary"
              >
              {subtitle}
            </Typography>
          </Box>
        </div>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default FlatCard;
