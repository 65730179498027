import React from 'react'

export default () => {
  return (
    <svg fill="#FFFFFF" width="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 592.18 800">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="cls-1" d="M588.09,53.1a78.69,78.69,0,0,0-39.6-45.6c-18-9-39.6-11.2-75.2.7L69,143c-35.6,11.9-51.6,26.5-60.6,44.6a78.79,78.79,0,0,0,35.3,105.8c18,9,39.6,11.2,75.2-.7l404.3-134.8c35.6-11.8,51.6-26.5,60.6-44.6a78.18,78.18,0,0,0,4.3-60.2"/><path class="cls-1" d="M588.09,302.6a78.69,78.69,0,0,0-39.6-45.6c-18-9-39.6-11.2-75.2.7L69,392.5c-35.6,11.9-51.6,26.5-60.6,44.6a78.79,78.79,0,0,0,35.3,105.8c18,9,39.6,11.2,75.2-.7l404.3-134.7c35.6-11.9,51.6-26.5,60.6-44.7a78.18,78.18,0,0,0,4.3-60.2"/><path class="cls-1" d="M588.09,552.2a78.69,78.69,0,0,0-39.6-45.6c-18-9-39.6-11.2-75.2.7L69,642.1c-35.6,11.9-51.6,26.5-60.6,44.6a78.79,78.79,0,0,0,35.3,105.8c18,9,39.6,11.2,75.2-.7L523.19,657c35.6-11.8,51.6-26.5,60.6-44.6a78.18,78.18,0,0,0,4.3-60.2"/>
        </g>
      </g>
    </svg>
  )
}