// import EricssonHildaRegular from '../assets/fonts/EricssonHilda-Regular.woff2'
// import EricssonHildaBold from '../assets/fonts/EricssonHilda-Bold.woff2'


const mapThemes = {
  eun: {
    attribution:"Map data &copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://creativecommons.org/licenses/by-sa/2.0/&quot;>CC-BY-SA</a>, Imagery &copy; <a href=&quot;https://www.mapbox.com/&quot;>Mapbox</a>",
    url:"https://api.mapbox.com/styles/v1/varunevolve/ckv26hkjp01ub14nxe2r5uw0h/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoidmFydW5ldm9sdmUiLCJhIjoiY2t2MjY4ZTdoMDExZjJvcGhzMGUxYWpkYyJ9.I2FZ9blSrjnvpm_g4WgipQ"
  },
  default: {
    attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url:"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
  }
}

const darkTheme = {
  props: {
    themeType: 'default',
    mapType: mapThemes.default
  },
  palette: {
    primary: {
      main: '#90caf9',
    },
    type: 'dark'
  }
};

const lightTheme = {
  props: {
    themeType: 'default',
    mapType: mapThemes.default
  },
  /*
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    }
  },
  shape: {
    borderRadius: 10,
  },
  */
  overrides: {
    /*
    RaMenuItemLink: {
      root: {
        borderLeft: '3px solid #fff',
      },
      active: {
        borderLeft: '3px solid #4f3cc9',
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow: 'none',
      },
      root: {
        border: '1px solid #e0e0e3',
        backgroundClip: 'padding-box',
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: '#fff',
        color: '#4f3cc9',
        boxShadow: 'none',
      }
    },
    */
    MuiAppBar: {
      colorSecondary: {
        color: '#808080',
        backgroundColor: '#fff',
      }
    },
    /*
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#f5f5f5',
      },
      barColorPrimary: {
        backgroundColor: '#d7d7d7',
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&$disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
      }
    }
    */
  }
};


const generateEricssonTheme = () => {

  // const ericssonHildaRegular = {
  //   fontFamily: 'EricssonHilda',
  //   fontStyle: 'normal',
  //   fontDisplay: 'swap',
  //   fontWeight: 400,
  //   src: `
  //     local('EricssonHilda'),
  //     local('EricssonHilda-Regular'),
  //     url(${EricssonHildaRegular}) format('woff2')
  //   `,
  //   unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
  // };
  
  // const ericssonHildaBold = {
  //   fontFamily: 'EricssonHilda',
  //   fontStyle: 'bold',
  //   fontDisplay: 'swap',
  //   fontWeight: 700,
  //   src: `
  //     local('EricssonHilda'),
  //     local('EricssonHilda-Bold'),
  //     url(${EricssonHildaBold}) format('woff2')
  //   `,
  //   unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF'
  // };
 
  const config = {
    primaryColor: '#1174E6',
    errorColor:'#D40D02',
    successColor: '#329864',
    primaryBoxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    lightBackground: '#EBEBEB',
    primaryText: '#242424',
    secondaryText: '#6A6A6A',
  }

  return {
    props: {
      themeType: 'eun',
      mapType: mapThemes.eun
    },
    palette: {
      primary: {
        main: config.primaryColor,
        light: '#4D97ED',
        dark: '#0069C2',
        contrastText: '#FFFFFF'
      },
      error: {
        main: config.errorColor,
        light: '#ED0E00',
        dark: '#BB0B02',
        contrastText: '#FFFFFF'
      },
      success: {
        main: config.successColor,
        contrastText: '#FFFFFF'
      },
      background: {
        paper: '#FFFFFF',
        default: config.lightBackground
      },
      text: {
        primary: config.primaryText,
        secondary: config.secondaryText,
      }
    },
    typography: {
      fontFamily: ['EricssonHilda', '"Open Sans"'].join(','),
    },
    shape: {
      borderRadius: 0
    },
    overrides: {
      // MuiCssBaseline: {
      //   '@global': {
      //     '@font-face': [ericssonHildaRegular, ericssonHildaBold],
      //   },
      // },
      MuiAppBar: {
        root: {
          boxShadow: 'none',
        },
        colorSecondary: {
          color: '#FAFAFA',
          backgroundColor: '#0C0C0C',
        }
      },
      MuiListItemIcon: {
        root: {
          color: config.primaryColor,
        }
      },
      MuiMenuItem: {
        root: {
          color: config.primaryText,
          '&:hover': {
            backgroundColor: config.primaryColor,
            color: '#FFFFFF',
            '& svg': {
              color: '#FFFFFF'
            }
          },
        },
      },
      RaMenuItemLink: {
        active: {
          color: config.primaryColor
        }
      },
      MuiButton: {
        root: {
          borderRadius: 4
        }
      },
      RaPaginationActions: {
        currentPageButton: {
          color: config.primaryText,
          borderColor: '#1174E6',
          borderBottom: `4px solid ${config.primaryColor}`,
          '&:hover': {
            backgroundColor: 'transparent',
          }
        },
      },
      MuiTablePagination: {
        toolbar: {
          display:'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }
      },
      RaButton: {
        '&:hover': {
          backgroundColor: 'transparent',
          border: 'none'
        }
      },
      MuiCard: {
        root: {
          boxShadow: config.primaryBoxShadow
        }
      }
    }
  }
}

const themes = {
  darkTheme,
  default: lightTheme,
  eun: generateEricssonTheme()
}

export default themes