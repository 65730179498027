const create = ({ properties, ...rest }) => {
	rest.required.push('id');

	return {
	  ...rest,
	  properties: {
	    id: {
	      type: 'string',
	      title: 'ID',
	      description: 'Must be an existing Lolo account ID'
	    },
	    ...properties
	  }
	}
};

export default {
	create
}