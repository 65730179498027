const create = ({ properties, required, ...rest }) => {
	return {
   ...rest,
   properties: {
     id: {
       type: 'string',
       title: 'ID',
       minLength: 1,
     },
     ...properties
   },
   required: [
       'id',
       ...required
   ]
 }
};

const list = ({ properties, ...rest }) => {
    return {
        ...rest,
        properties: {
            id: {
                type: 'string',
                title: 'ID',
                readOnly: true,
            },
            ...properties
        }
    }
};

const edit = ({ properties, required, ...rest }) => {
    return {
        ...rest,
        properties: {
            id: {
                type: 'string',
                title: 'ID',
                readOnly: true,
            },
            ...properties
        },
        required: [
            'id',
            ...required
        ]
    }
}

export default {
  create,
  list,
  edit,
}
