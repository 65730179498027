import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { MapContainer, TileLayer } from 'react-leaflet';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

const { getLayersGeoJson, setLayersGeoJson, getLayers } = require('../map_utils');

const useStyles = makeStyles(theme => ({
  card: {
    height: 'calc(100vh - 165px)'
  }
}));

const Map = props => {
  const classes = useStyles(props);
  const [map, setMap] = React.useState(null);

  const handleMapCreated = map => {
    setMap(map);

    const setZoomPan = () => {
      const { lat, lng } = map.getCenter();
      
      props.setZoomPan({
        center: [ lat, lng ],
        zoom: map.getZoom()
      })
    }

    const setGeoJson = () => {
      props.setGeoJson(getLayersGeoJson(map));
    }

    map.on('zoomend', setZoomPan);
    map.on('moveend', setZoomPan);
    map.on('pm:create', setGeoJson);
    map.on('pm:remove', setGeoJson);

    map.pm.addControls({
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false
    });

    setLayersGeoJson(map, props.geoJson, setGeoJson);
  }

  React.useEffect(() => {
    if (!map) return;
   
    map.pm.setPathOptions({ color: props.colorHex });

    getLayers(map).forEach(layer => layer.setStyle({
      color: props.colorHex
    }));
  }, [ map, props.colorHex ]);

  return (
    <MapContainer
      whenCreated={handleMapCreated}
      center={props.center}
      zoom={props.zoom} 
      zoomControl={false}
      className={classes.card}
      >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
    </MapContainer>
  );
};

export default Map;