import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Box, Typography, Divider } from '@material-ui/core';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import { useGlobalContext } from '../hooks/globalContext';

const useStyles = makeStyles(theme => ({
  card: {
  	height: 350
  }
}));

export default props => { 
	const { pos, accuracy, address } = props.location;
	const classes = useStyles(props);
	const { selectedTheme } = useGlobalContext()
	const latLon = [ pos.lat, pos.lon ];

	return (
		<MapContainer center={latLon} zoom={11} scrollWheelZoom={false} className={classes.card}>
		  <TileLayer {...selectedTheme.props.mapType}/>
			{isNaN(accuracy) ? null : (
				<Circle
					center={latLon} 
					pathOptions={{ color: selectedTheme.palette.primary.main, weight: 1 }} 
					radius={accuracy} 
				/>
			)}
		  <Marker position={latLon}>
		    <Popup>
		      <p>Accuracy: {accuracy}m</p>
		      {address}
		    </Popup>
		  </Marker>
		</MapContainer>
	)
};