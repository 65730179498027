import React from 'react';

import { Toolbar } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';

const FormButtons = ({ history }) => (
  <Toolbar>
    <Box
    	display='flex' 
    	justifyContent='space-between'
    	width='100%'
    	>
      <Button
        type='submit'
        color='primary'
        variant='contained'
        startIcon={<SaveIcon />}
        >Save</Button>
      <Button onClick={() => history.go(-1)}>
        Cancel
      </Button>
    </Box>
  </Toolbar>
);

export default FormButtons;
