import React from 'react';

import * as ra from 'react-admin';
import { Grid, Button } from '@material-ui/core';

import BackIcon from '@material-ui/icons/NavigateBefore';
import NextIcon from '@material-ui/icons/NavigateNext';

export const ExpandPanel = ({ id, record, resource }) => (
  <pre style={{ fontSize: '1.1rem' }}>{JSON.stringify(record, null, 2)}</pre>
);

const PrevNext = props => {
  const { page, setPage } = ra.useListContext();

  return (
    <Grid container justify="flex-end" style={{ height: 50 }}>
      {page > 1 ? (
        <Button color="primary" key="prev" onClick={() => setPage(page - 1)}>
          <BackIcon />
          PREV
        </Button>
      ) : null}
      <Button color="primary" key="next" onClick={() => setPage(page + 1)}>
        NEXT
        <NextIcon />
      </Button>
    </Grid>
  );
};

const AccountingReport = props => {
  return (
    <ra.List 
      {...props}
      bulkActionButtons={false}
      title='Accounting Report'
      filters={filters}
      actions={<Actions />}
      pagination={<PrevNext />}
      perPage={50}
      loading={ra.Loading}
      sort={{ field: 'time', order: 'DESC' }}
      filterDefaultValues={{
        fromDate: new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString(),
        toDate: new Date().toISOString()
      }}
      >
      <DataGrid />
    </ra.List>
  );
};

const DataGrid = props => {
  const { loading } = ra.useListContext();

  if (loading) return <ra.Loading />;

  return (
    <ra.Datagrid
      {...props}
      isRowExpandable={() => true}
      expand={<ExpandPanel />}
      optimized
      >
      <ra.DateField source='time' showTime={true} />
      <ra.FunctionField label='Device' render={
        record => <FilterLink record={record} source='deviceId' label='deviceName' />} 
      />
      <ra.FunctionField label='APN' render={
        record => <FilterLink record={record} source='apn' />} 
      />
      <ra.FunctionField label='Status' render={
        record => <FilterLink record={record} source='statusType' />} 
      />
      <ra.FunctionField label='Terminate Cause' render={
        record => <FilterLink record={record} source='terminateCause' />} 
      />
      <ra.FunctionField label='Bytes' render={
        record => <ra.TextField record={record} source='bytes' />}
      />
    </ra.Datagrid>
  );
}

const FilterLink = ({ record, source, label = source }) => {
  const { filterValues, filterDefaultValues, setFilters } = ra.useListContext();

  const onClick = ev => {
    ev.preventDefault();
    setFilters({ ...filterDefaultValues, ...filterValues, [source]: record[source] });
  }

  return (
    <ra.Link to='#' onClick={onClick}>
      {record[label]}
    </ra.Link>
  );
};

const style = { maxWidth: 140 };

const filters = [
  <ra.DateTimeInput 
    source="fromDate" label='From'
    alwaysOn
    style={style}
  />,
  <ra.DateTimeInput 
    source="toDate" label='To'
    alwaysOn
    style={style}
  />,
  <ra.TextInput label='Device' source='deviceId' style={style}/>,
  <ra.TextInput label='MSISDN' source='msisdn' style={style}/>,
  <ra.TextInput label='IMSI' source='imsi' style={style}/>,
  <ra.TextInput label='APN' source='apn' style={style}/>,
  <ra.TextInput label='Status' source='statusType' style={style}/>,
  <ra.TextInput label='Terminate Cause' source='terminateCause' style={style}/>,
  <ra.TextInput label='Session Id' source='acctSessionId' style={style}/>
];

const Actions = props => {
  return (
    <ra.TopToolbar>
      <ra.FilterButton filters={filters}/>
      <ra.ExportButton />
    </ra.TopToolbar>
  );
};

export default AccountingReport