import revocableSecret from "../widgets/RevocableSecret";

const edit = (schema) => {
    const { uiSchema = {}, ...rest } = schema;
    const { secretHint: _, ...restUiSchema } = uiSchema;

    return {
        uiSchema: {
            secretHint: {
                "ui:widget": "revocableSecret",
            },
            ...restUiSchema,
        },
        ...rest,
    }
};

const create = (schema) => {
    const { uiSchema = {}, ...rest } = schema;
    const { secretHint: _, ...restUiSchema } = uiSchema;

    return {
        uiSchema: {
            secretHint: {
                "ui:widget": "hidden",
            },
            ...restUiSchema,
        },
        ...rest,
    }
};

export default {
    edit,
    create
}
