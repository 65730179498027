import { dataProvider as initDataProvider } from '@lolocompany/react-admin-lolo';
import { stringify } from 'query-string';

const unifyDataProvider = apiUrl => {
  const dataProvider = initDataProvider(apiUrl);

  dataProvider.getList = async function(resource, params, queryOpts) {
    const {
      page = 1,
      perPage = 10
    } = params.pagination || {};

    const {
      field = 'time',
      order = 'ASC'
    } = params.sort || {};

    const query = {
      limit: perPage,
      offset: (page - 1) * perPage,
      sort: `${field} ${order.toLowerCase()}`,
      ...buildQs(params.filter),
      ...queryOpts
    };

    const { data: events } = await this.sendRequest(
      `/accounting?${stringify(query)}`
    );

    await this._addDeviceNames(events);

    return {
      data: events,
      total: 1000
    }
  }

  dataProvider._addDeviceNames = async function(events) {
    const deviceIds = new Set();
    
    events.forEach((event, i) => {
      deviceIds.add(event.deviceId);  
      event.id = i;
    });

    const { data: { devices }} = await this.sendRequest(
      '/devices?limit=100&pick[]=id&pick[]=name&' +
      Array.from(deviceIds).map(id => 'q[id]=' + id).join('&')
    );

    const deviceNameById = devices.reduce((memo, device) => {
      memo[device.id] = device.name;
      return memo;
    }, {});

    events.forEach(event => {
      event.deviceName = deviceNameById[event.deviceId];
    });
  }

  return dataProvider;
};

const buildQs = (filter = {}) => {
  return Object.entries(filter).reduce((memo, [k, v]) => {
    memo[`q[${k}]`] = v;
    return memo;
  }, {});
};

export default unifyDataProvider;