import React from 'react'
import { LoloList, useAdminContext } from '@lolocompany/react-admin-lolo';

/*
 * Disable create and edit for enteprise users
 */

const ListApns = props => {
	const { selectedAccount } = useAdminContext();
	let { hasCreate, hasEdit } = props;

	if (!selectedAccount) return null;

	if (!selectedAccount.isRoot) {
		hasCreate	= false;
		hasEdit = false;
	}

	return (
		<LoloList
			{...props}
			hasCreate={hasCreate}
			hasEdit={hasEdit}
		/>
	);
};

export default ListApns;