import React from 'react';
import * as ra from 'react-admin';

const EditApp = props => {
  const { record } = ra.useEditController({ ...props, undoable: false });

  if (!record) {
    return null;
  }

  return (
    <div>
      <EditActions {...props} />
      <ra.TitleForRecord
        title='Edit App'
        record={record}
      />
      <LoloIde record={record} />
    </div>
  );
};

const LoloIde = props => {
  const { id, accountId } = props.record;
  const [loading, setLoading] = React.useState(true);

  const onLoad = () => setTimeout(() => {
    setLoading(false);
  }, 200);

  return (
    <>
      {loading ? <ra.Loading/> : null}
      <iframe
        title='Edit App'
        src={`https://www.lolo.co/ide/${accountId}/build/applications/${id}/edit?embed=1`}
        height='800px'
        width='100%'
        style={{border: 0}}
        onLoad={onLoad}
      />
    </>
  );
}

const EditActions = ({ basePath, resource }) => (
  <ra.TopToolbar>
    <ra.ListButton basePath={basePath} resource={resource} />
  </ra.TopToolbar>
);

export default EditApp