import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {
    Paper, TextField, Box, Table,
    TableContainer,
    TableSortLabel,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Menu,
    MenuItem, LinearProgress, TableFooter,
} from "@material-ui/core";
import {
    FilterList,
    HighlightOff,
    GetApp
} from "@material-ui/icons";
import {useAdminContext} from "@lolocompany/react-admin-lolo";

const downloadJson = (filename, value) => {
    const blob = new Blob([JSON.stringify(value, null, 2)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    URL.revokeObjectURL(url);
};

const downloadCsv = (filename, columns, value) => {
    if (!(columns instanceof Array && columns.every(c => typeof c === 'object' && c.key && c.label))) {
        throw new Error("expected columns to be an Array of strings");
    }

    if (!(value instanceof Array && value.every(r => typeof r === 'object'))) {
        throw new Error("expected value to be an Array");
    }

    const rows = [];
    rows.push(columns.map(c => c.label).join(','));

    for (const rowValue of value) {
        const row = [];
        for (const column of columns) {
            row.push(rowValue[column.key]);
        }

        rows.push(row);
    }

    const res = rows.join("\n");

    const blob = new Blob([res], { type: "application/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    URL.revokeObjectURL(url);
};

const BillingReport = _props => {
    const now = new Date();
    const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1);

    const [period, setPeriod] = useState( { year: lastMonth.getFullYear(), month: lastMonth.getMonth() });
    const [filters, setFilters] = useState({});
    const [sort, setSort] = useState(null);
    const [data, setData] = useState(null);
    const [exportPopoverAnchorEl, setExportPopoverAnchorEl] = useState(null);

    const adminContext = useAdminContext();

    useEffect(() => {
        const apiUrl = adminContext.dataProvider.apiUrl;
        const url = `${apiUrl}/report/billing?year=${period.year}&month=${period.month + 1}&version=1`;
        const accountId = adminContext.selectedAccount?.id;

        adminContext.authProvider
            .checkAuth()
            .then(auth => auth.idToken.jwtToken)
            .then(token => new Request(url, {
                headers: new Headers({
                    Authorization: token,
                    'Lolo-Account-Id': accountId,
                }),
            }))
            .then(req => fetch(req))
            .then(res => {
                if (res.ok) {
                    return res;
                } else {
                    return res.json().then(({error}) => Promise.reject(error))
                }
            })
            .then(res => res.json())
            .then(res => setData(res));
    }, [adminContext, period]);

    const columns = [
        { key: "account_id", label: "Account ID", alignment: "left", filterable: true },
        { key: "name", label: "Account Name", alignment: "left", filterable: true },
        { key: "active_devices", label: "Active Devices", alignment: "right", filterable: false },
        { key: "GB", label: "GB", alignment: "right", filterable: false },
        { key: "GB_per_device", label: "GB per device", alignment: "right", filterable: false },
    ];

    const onSort = key => () => {
        if (!sort) {
            setSort({by: key, order: "asc"});
            return;
        }

        if (sort.by !== key) {
            setSort({by: key, order: "asc"});
            return;
        }

        if (sort.order === "asc") {
            setSort({by: key, order: "desc"});
            return;
        }

        setSort(null);
    };


    let finalData;
    if (data) {
        finalData = data
            .filter(row => Object.entries(filters)
                .every(([key, { value }]) => row[key]?.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase()))
            );
        if (sort) {
            const normalSort = (a, b) => a[sort.by] < b[sort.by] ? -1 : 1;
            const factor = sort.order === "asc" ? -1 : 1;
            finalData.sort((a, b) => factor * normalSort(a, b));
        }
    }

    const handleDownloadCsv = () => {
        setExportPopoverAnchorEl(null);
        downloadCsv(`billing-report-${period.year}-${(period.month + 1).toString().padStart(2, '0')}.csv`, columns, finalData);
    }

    const handleDownloadJson = () => {
        setExportPopoverAnchorEl(null);
        downloadJson(`billing-report-${period.year}-${(period.month + 1).toString().padStart(2, '0')}.json`, finalData);
    }

    return (
        <Box>
            <TextField type="month" value={period ? `${period.year}-${(period.month + 1).toString().padStart(2, '0')}` : ""} onChange={(event) => {
                setData(null);

                const value = event.target.value;
                if (!value) {
                    setPeriod(null);
                    return;
                }

                const [year, month] = value.split("-");
                setPeriod({ year: parseInt(year), month: parseInt(month) - 1 });
            }}/>
            <MenuButton
                label={<FilterList/>}
                items={columns
                    .filter(column => !Object.keys(filters).includes(column.key))
                    .filter(column => column.filterable)
                    .map(column => ({
                        label: column.label,
                        onClick: () => setFilters({
                            ...filters,
                            [column.key]: {
                                label: column.label,
                                value: ""
                            }
                        })
                    }))
                }
            />
            {Object.entries(filters).map(([key, { label, value }]) =>
                <Box component="span">
                    <Button onClick={() => {
                        const {[key]: _, ...rest} = filters;
                        setFilters(rest);
                    }}><HighlightOff/></Button>
                    <TextField label={label} value={value} onChange={event => {
                        setFilters({...filters, [key]: { label, value: event.target.value }});
                    }}></TextField>
                </Box>
            )}
            <TableContainer component={Paper} size="small">
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map(column =>
                                <TableCell align={column.align}>
                                    <TableSortLabel active={sort?.by === column.key} { ...(sort?.by === column.key ? {direction: sort.order} : {})} onClick={onSort(column.key)}>
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {finalData ? finalData.map(row =>
                            <TableRow>
                                {columns.map(column => <TableCell align={column.align}>{row[column.key]}</TableCell>)}
                            </TableRow>
                        ) : <TableRow>
                            {columns.map(_ => <TableCell><LinearProgress/></TableCell>)}
                        </TableRow>}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Button onClick={ev => setExportPopoverAnchorEl(ev.currentTarget)}>
                                <GetApp/>
                                Export
                            </Button>
                            <Menu open={Boolean(exportPopoverAnchorEl)} anchorEl={exportPopoverAnchorEl} onClose={() => setExportPopoverAnchorEl(null)} keepMounted>
                                <MenuItem onClick={handleDownloadCsv}>CSV</MenuItem>
                                <MenuItem onClick={handleDownloadJson}>JSON</MenuItem>
                            </Menu>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Box>
    )
};

const MenuButton = ({label, items}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuId = useId();

    return (
        <Box component="span">
            <Button aria-controls={menuId} aria-haspopup="true" onClick={handleClick}>{label}</Button>
            <Menu id={menuId} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>{items.map(item => <MenuItem onClick={() => {handleClose(); item.onClick();}}>{item.label}</MenuItem>)}</Menu>
        </Box>
    )
}

const useId = () => {
    const [id] = useState(() => {
        return crypto.randomUUID()
    });

    return id;
}

export default BillingReport;
