const list = (schema, pSchema, selectedAccount) => {
	if (!selectedAccount || !selectedAccount.isParent) return schema;

	const { name, ...rest } = schema.properties;

	return {
	  ...schema,
	  properties: {
	  	name,
	  	accountId: {
	  		"type": "string",
	  		"title": "Account"
	  	},
	  	...rest
	  },
	  uiSchema: {
	  	...schema.uiSchema,
	  	accountId: schema.uiSchema.apnId
	  }
	}
};

export default {
	list
}