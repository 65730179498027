import React from 'react';
import { Confirm } from 'react-admin';
import { withStyles, Typography, LinearProgress, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const LinearProgressBar = withStyles((theme) => ({
  root: {
    height: 15,
    border:`2px solid ${theme.palette.grey[400]}`
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.white,
  }
}))((props) => {
  const {title = '', subtitle = '', onReset} = props
  
  const [ open, setOpen ] = React.useState(false);

  return (
    <div style={{width: '100%'}}>
      <Box m={1}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1}>
                <Typography variant="h6">{title}</Typography>
              </Box>
              <Box>
                <Typography variant="subtitle2">{subtitle}</Typography>
              </Box>
            </Box>
            <LinearProgress {...props} onClick={() => setOpen(true)}/>
          </Box>
          <Box title='Delete bucket'>
            <DeleteIcon
              style={{
                position: 'relative', 
                top: 18, 
                left: 3, 
                cursor: 'pointer'
              }}
              onClick={() => setOpen(true)}
            />
          </Box>
        </Box>
      </Box>
      <Confirm
        isOpen={open}
        title={`Delete bucket`}
        content={`Are you sure that you want to delete bucket ${title}?`}
        onConfirm={async () => {
          await onReset();
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
        confirm="Confirm"
        cancel="Cancel"
      />
    </div>
  )
})

export default LinearProgressBar