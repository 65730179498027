import { dataProvider as initDataProvider } from '@lolocompany/react-admin-lolo';
import accountingDataProvider from './accountingDataProvider';

export default baseUrl => {
  const configs = [
    {
      dataProvider: initDataProvider('https://dev.lolo.company/api'),
      resources: [ 'apps', 'deployments' ]
    },
    {
      dataProvider: accountingDataProvider(baseUrl),
      resources: [ 'accounting' ]
    }
  ];

  return new Proxy(initDataProvider(baseUrl), {
    get(target, prop) {
      if (typeof target[prop] !== 'function') {
        return target[prop];
      }

      return function(...args) {
        // stardard datasource method calls, such as getList
        let resource = args[0];

        if (prop === 'sendRequest' && resource.startsWith('/schemas')) {
          // intercept schema requests
          resource = resource.split('/').pop().split(-1) + 's';
        }

        const config = configs.find(it => (it.resources.includes(resource)));
        const delegate = config ? config.dataProvider : target;

        return delegate[prop](...args);
      }
    }
  });
}