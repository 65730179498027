import React from 'react';
import * as ra from 'react-admin';
import { Typography } from '@material-ui/core';

const Identities = props => {
  return <>
    <Typography variant="h5">Identities</Typography>
    <ra.ReferenceManyField
      reference='device-ids'
      target='deviceId'
      addLabel={false}
      {...props}
    >
      <ra.Datagrid>
        <ra.TextField label='Value' source='value' />
        <ra.TextField label='Type' source='type' />
        <ra.DateField source='createdAt' />
      </ra.Datagrid>
    </ra.ReferenceManyField>
  </>
}

export default Identities;