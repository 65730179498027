const list = (schema, pSchema, selectedAccount) => {
	const { name, enabled, smscGroupId, maxConnections, maxConnectionRps } = schema.properties;

	return {
	  ...schema,
	  properties: {
	  	name,
	  	enabled,
	  	smscGroupId,
	  	maxConnections,
	  	maxConnectionRps
	  }
	}
};

export default {
	list
}