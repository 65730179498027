import React from 'react';
import {
  FormControl,
  InputLabel,
  Input,
  Button,
  Paper,
  Link
} from "@material-ui/core";

const examples = {
  accessRequest: `
{
  "code": "Access-Request",
  "attributes": [
    ["Called-Station-Id", "apn-1"],
    ["Vendor-Specific", "3GPP", [
      ["3GPP-IMSI", "00000001"]
    ]]
  ]
}
`,
  accountingRequest: `
{
  "code": "Accounting-Request",
  "attributes": [
    ["Called-Station-Id", "apn-1"],
    ["Acct-Session-Id", "0"],
    ["Acct-Input-Octets", 1000],
    ["Acct-Input-Gigawords", 0],
    ["Acct-Output-Octets", 1000],
    ["Acct-Output-Gigawords", 0],
    ["Vendor-Specific", "3GPP", [
      ["3GPP-IMSI", "00000001"],
      ["3GPP-RAT-Type","EUTRAN"],
      ["3GPP-User-Location-Info", {
        "type": "Buffer",
        "data": [130,66,240,128,133,153,66,240,112,4,205,152,3]
      }]
    ]]
  ]
}`
};

class Simulator extends React.Component {
  state = {
    request: '',
    response: ''
  }

  sendRequest = async ev => {
    ev.preventDefault();

    this.setState({ response: '' });

    const res = await fetch(process.env.REACT_APP_API_URL + '/radtest', {
      method: 'POST',
      mode: 'cors',
      body: this.state.request,
      headers: { 'content-type': 'application/json' }
    });

    this.setState({
      response: await res.json()
    });
  }

  render() {
    const { request, response } = this.state;
    return (
      <Paper style={{
        marginTop: '20px',
        padding: '16px'
      }}>
        <div>
          <Link onClick={() => {
            this.setState({ request: examples.accessRequest });
          }}>Access-Request</Link>&nbsp;&nbsp;&nbsp;
          <Link onClick={() => {
            this.setState({ request: examples.accountingRequest });
          }}>Accounting-Request</Link>&nbsp;&nbsp;&nbsp;
        </div>
        <form style={{ width: "100%" }}>
          <FormControl margin="normal" fullWidth>
            <InputLabel htmlFor="request">RADIUS Request</InputLabel>
            <Input
              key="request"
              value={request}
              multiline
              rows={23}
              style={{ fontFamily: 'monospace', fontSize: 14 }}
              onChange={e => this.setState({ request: e.target.value })}
            />
          </FormControl>
          <Button onClick={this.sendRequest} variant="contained" color="primary" size="medium">
            Send Request
          </Button>
        </form>
        { response && (
          <div style={{marginTop: '20px'}}>
          <InputLabel htmlFor="request">RADIUS response</InputLabel>
          <pre>
          { JSON.stringify(response, null, 2) }
          </pre>
          </div>
        )}
     </Paper>
    );
  }
}

export default Simulator;