import React from 'react';
import TimeAgo from 'react-timeago'
import { Typography } from '@material-ui/core';


const LastActive = ({ acctSessions }) => {
  const res = Object.values(acctSessions).reduce((memo, item) => {
    if (item.ts > memo) {
      memo = item.ts;
    }
    return memo;
  }, 0);

  if (!res) return null;

  return (
    <Typography variant="subtitle1" gutterBottom>
      Last activity <TimeAgo date={res} live={false} />
    </Typography>
  )
};

export default LastActive;