const list = (schema, pSchema, selectedAccount) => {
	if (!selectedAccount || selectedAccount.isRoot) return schema;

	const { cspId, ...rest } = schema.properties;

	return {
	  ...schema,
	  properties: rest
	}
};

export default {
	list
}