import React from 'react';
import {LoloList, useAdminContext} from '@lolocompany/react-admin-lolo';

const ListSmscGroups = props => {
    const adminContext =  useAdminContext();
    const accountId =  adminContext.selectedAccount?.id;
    const LOLO_ACCOUNT_ID = 'qp5MTaQcQkGYKLn8TkuSYZ';

    if (!accountId) {
        return null;
    }

    return (
        <div>
            <h2>Owned</h2>
            <LoloList {...props} filter={{accountId}}/>
            <h2>Shared by Lolo</h2>
            <LoloList {...props} filter={{accountId: LOLO_ACCOUNT_ID}} hasCreate={false} hasEdit={false}/>
        </div>
    );
};

export default ListSmscGroups;
