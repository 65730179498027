import React from 'react';
import * as ra from 'react-admin';
import { Typography } from '@material-ui/core';
import * as timeago from 'timeago.js';

import Chip from '@material-ui/core/Chip';

const SessionsSummary = ({ acctSessions }) => {

  return (
    <>
      <Typography variant="h5">Sessions</Typography>
      <ra.ReferenceManyField
        reference='sessions'
        target='deviceId'
        addLabel={false}
      >
        <ra.Datagrid header={"Details"}>
          <ra.ReferenceField label='APN' source='apnId' reference='apns'>
            <ra.TextField source='name' style={{ wordBreak: 'break-word' }} />
          </ra.ReferenceField>
          <ra.TextField label='IP address' source='ip' />
          <ra.ReferenceField label='ACL' source='aclId' reference='acls'>
            <ra.TextField source='name' style={{ wordBreak: 'break-word' }} />
          </ra.ReferenceField>
          <ra.FunctionField
            label="Status"
            render={renderSessionStatus(acctSessions)}
          />
        </ra.Datagrid>
      </ra.ReferenceManyField>
    </>
  )
}

const renderSessionStatus = acctSessions => session => {
  const acctSession = Object.values(acctSessions).reduce((memo, item) => {
    if (item.apnId === session.apnId && item.ts > memo.ts) {
      memo = item;
    }
    return memo;
  }, { ts: 0 });

  if (!acctSession.ts) return null;

  const ago = timeago.format(acctSession.ts);
  let label, color, title;

  if (acctSession.tc) {
    label = 'Disconnected';
    color = 'default';
    title = 'Session ended ' + ago + ' due to ' + acctSession.tc;

  } else {
    label = 'Connected';
    color = 'primary';
    title = 'Session started ' + ago;
  }

  return <Chip
    title={title}
    color={color}
    label={label}
    size='small'
  />
};


export default SessionsSummary