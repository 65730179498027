import React from "react";
import { Grid } from "@material-ui/core";
import VolumeIcon from "@material-ui/icons/DataUsageOutlined";
import BarChart from "../common/BarChart";
import Map from "../common/Map";
import CardWithIcon from "../common/CardWithIcon";
import { formatBytes } from "../dashboard/utils";
import { useGlobalContext } from "../hooks/globalContext";
import FlatCard from "../common/FlatCard";

const Usage = ({ stateData, usageData }) => {
  const { selectedTheme } = useGlobalContext();
  const {
    props: { themeType },
  } = selectedTheme;

  const generateUsageChartData = () => {
    const { byDate } = usageData;
    return byDate.map((item) => ({ key: item.date, value: item.bytes }));
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {stateData.location?.pos ? (
          <Grid item>
            <Map location={stateData.location} />
          </Grid>
        ) : null}
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {themeType === "eun" ? (
                <FlatCard
                  subtitle="Usage Today"
                  title={formatBytes(usageData.byDate.slice(-1)[0]?.bytes || 0)}
                />
              ) : (
                <CardWithIcon
                  icon={VolumeIcon}
                  title="Usage Today"
                  subtitle={formatBytes(
                    usageData.byDate.slice(-1)[0]?.bytes || 0
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {themeType === "eun" ? (
                <FlatCard
                  subtitle="Total Usage"
                  title={formatBytes(stateData.usage.bytes)}
                />
              ) : (
                <CardWithIcon
                  icon={VolumeIcon}
                  title="Total Usage"
                  subtitle={formatBytes(stateData.usage.bytes)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: -20 }}>
          <BarChart
            title="Usage Trend"
            data={generateUsageChartData()}
            color={selectedTheme.palette.primary.main}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Usage;
