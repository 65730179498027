import * as React from 'react';
import * as ra from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Menu from './Menu';
import EunLogo from '../assets/EunLogo'
import logo from './logo.png';
import { useGlobalContext } from '../hooks/globalContext';

const Logo = ({themeType}) => {

  const getEun = () => {
    return (
      <div style={{display:'flex'}}>
        <EunLogo />
        <span style={{marginLeft: 16}}>Unified Networking</span>
      </div>
    )
  }

  const platform = {
    eun: getEun(),
    default: 'PVPC'
  }

  return (
    <>
      {platform[themeType] || platform.default}
    </>
  );  
}

const useStyles = makeStyles({
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

const AppBar = props => {
  const classes = useStyles();
  return (
    <ra.AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        style={{flex: props.themeType === 'eun' ? 0 : 1}}
        className={classes.title}
        id="react-admin-title"
      />
      <Logo themeType={props.themeType} />
      <span className={classes.spacer} />
    </ra.AppBar>
  );
};


const Sidebar = props => <ra.Sidebar {...props} size={200} />;

export default props => {
  const { selectedTheme } = useGlobalContext()
  return (
    <ra.Layout
      {...props}
      appBar={(props) => (<AppBar {...props} themeType={selectedTheme.props.themeType} />)}
      sidebar={Sidebar}
      menu={Menu}
      theme={selectedTheme}
    />
  );
};
