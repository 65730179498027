import L from 'leaflet';

export const getLayers = map => {
  let layers = [];

  map.eachLayer(layer => {
    if (
      layer instanceof L.Polyline ||
      layer instanceof L.Marker ||
      layer instanceof L.Circle ||
      layer instanceof L.CircleMarker
    ) {
      layers.push(layer);
    }
  });

  layers = layers.filter(layer => !!layer.pm);
  layers = layers.filter(layer => !layer._pmTempLayer);

  return layers;
};

export const getLayersGeoJson = map => {
  return getLayers(map).map(layer => {
    const json = layer.toGeoJSON();

    if (layer instanceof L.Circle) {
      json.properties.radius = layer.getRadius();
    }

    return json;
  });
}

export const setLayersGeoJson = (map, geoJson, onEdit) => geoJson.forEach(item => {
	const layer = L.geoJSON(item, {
    pointToLayer: (feature, latlng) => {
    	return new L.Circle(latlng, feature.properties.radius);
    }
  });

  layer.on('pm:edit', onEdit);
  layer.addTo(map);
});