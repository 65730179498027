import React, { useEffect, useState } from "react";
import * as ra from "react-admin";
import { formatBytes } from '../dashboard/utils';

import {
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";

const PacketCaptures = props => {
  const handleClick = (id, baseUrl, file) => {
    window.location = file.url;
  };

  return (
    <Card className={props.classes.card} style={{marginTop: 20}}>
      <CardContent>
        <Typography variant="h5">Packet Captures</Typography>
        <ra.ReferenceManyField
          reference='packet-captures'
          target='deviceId'
          addLabel={false}
          perPage={10}
          {...props}
          >
          <>
          <ra.Datagrid rowClick={handleClick} title='Click to download'>
            <ra.FunctionField
              label='File' 
              render={file => {
                try {
                  const [ y1, y2, mn, d, h, m, s] = file.id.match(/(.{2})/g);
                  return `${y1}${y2}-${mn}-${d} ${h}:${m}:${s}`;
                  
                } catch (err) {
                  return file.id;
                }
              }} 
            />
            <ra.FunctionField
              label='Size' 
              render={file => formatBytes(file.bytes)} 
            />
          </ra.Datagrid>
          <ra.Pagination rowsPerPageOptions={[]} />
          </>
        </ra.ReferenceManyField>
      </CardContent>
    </Card>
  );
}

export default PacketCaptures