import React, { useEffect, useState } from "react";
import * as ra from "react-admin";
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Timeline from "./Timeline";
import SessionsSummary from "./SessionsSummary";
import Buckets from "./Buckets";
import LastActive from "./LastActive";
import Identities from "./Identities";
import Usage from "./Usage";
import PacketCaptures from './PacketCaptures';

const useStyles = makeStyles((theme) => ({
  show: {
    backgroundColor: "transparent",
    boxShadow: "none",
    padding: theme.spacing(1),
  },
  card: {
    marginBottom: 20,
  },
}));

const DeviceDetailDashboard = ({ ...props }) => {
  const classes = useStyles();
  const { record } = props;

  const [stateData, setStateData] = useState({
    usage: { bytes: 0, bytesByDate: {} },
    buckets: {},
    acctSessions: {},
    network: {},
    location: {},
  });

  const [usageData, setUsageData] = useState({
    byDate: [],
    today: 0,
  });

  const [aggsData, setAggsData] = useState({});
  const [apns, setApns] = useState({});

  const dataProvider = ra.useDataProvider();

  useEffect(() => {
    if (!record.id) return;

    dataProvider
      .sendRequest(`/devices/${record.id}/state`)
      .then((res) => {
        setStateData(res.data);
        return res;
      })
      .then((res) => {
        const ids = Object.keys(res.data.buckets);
        return dataProvider.getMany("aggregations", { ids });
      })
      .then((res) => {
        setAggsData(
          res.data.reduce((memo, agg) => ({ ...memo, [agg.id]: agg }), {})
        );
      });

    dataProvider.sendRequest(`/devices/${record.id}/usage`).then((res) => {
      setUsageData(res.data);
    });

    dataProvider.sendRequest(`/apns?limit=100`).then((res) => {
      const apnsMap = res.data.apns.reduce((memo, item) => {
        memo[item.id] = item.name;
        return memo;
      }, {});
      setApns(apnsMap);
    });
  }, [dataProvider, record.id, setStateData, setAggsData, setUsageData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              {record.name}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Created at{" "}
              <ra.DateField record={record} source="createdAt" showTime />
            </Typography>
            <LastActive acctSessions={stateData.acctSessions} />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <Identities />
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <SessionsSummary acctSessions={stateData.acctSessions} />
            <Timeline
              apns={apns}
              acctSessions={stateData.acctSessions}
            />
          </CardContent>
        </Card>
        { stateData.buckets && Object.keys(stateData.buckets).length ? (
          <Card className={classes.card}>
            <CardContent>
              <Buckets
                id={stateData.id}
                buckets={stateData.buckets}
                aggsData={aggsData}
              />
          </CardContent>
        </Card>) : null}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Usage stateData={stateData} usageData={usageData} />
        <PacketCaptures classes={classes}/>
      </Grid>
    </Grid>
  );
};

const ShowDevice = (props) => {
  const classes = useStyles();

  return (
    <ra.Show classes={{ card: classes.show }} {...props}>
      <ra.SimpleShowLayout>
        <DeviceDetailDashboard />
      </ra.SimpleShowLayout>
    </ra.Show>
  );
};

export default ShowDevice;
