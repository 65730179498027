import React, {useContext, useState} from "react";
import {
    ButtonGroup,
    TextField,
    Box,
    styled,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions, Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import {useRefresh} from "react-admin";
import {useAdminContext} from "@lolocompany/react-admin-lolo";
import {useLocation} from 'react-router-dom';

const GappedBox = styled(Box)({
    gap: '5%'
});

const DeleteButton = styled(Button)({
    color: '#EC453D'
});

const RevocableSecret = (props) => {
    const title = props?.schema?.title;
    const value = props?.value;

    const [showSecretRevocationConfirmationDialog, setShowSecretRevocationConfirmationDialog] = useState(false);
    const [newSecretValue, setNewSecretValue] = useState(undefined);
    const [showSecretDeletionConfirmationDialog, setShowSecretDeletionConfirmationDialog] = useState(false);
    const [error, setError] = useState(undefined);

    const refresh = useRefresh();
    const adminContext = useAdminContext();
    const location = useLocation();
    const id = location.pathname.split('/').pop();
    const apiUrl = adminContext.dataProvider.apiUrl;
    const url = `${apiUrl}/forwarding/${id}/secret`;
    const accountId = adminContext.selectedAccount?.id;

    const sendReq = async method => {
        const auth = await adminContext.authProvider.checkAuth();

        const req = new Request(url, {
            method: method,
            headers: new Headers({
                Authorization: auth.idToken.jwtToken,
                'Lolo-Account-Id': accountId,
            }),
        });

        const res = await fetch(req);

        if (res.ok) {
            return await res.json();
        } else {
            const { error } = await res.json();
            throw new Error(error);
        }
    }

    const handleRevoke = async () => {
        setShowSecretRevocationConfirmationDialog(false);
        try {
            const res = await sendReq('POST');
            setNewSecretValue(res.value);
        } catch (err) {
            setError(err.message);
        }

        refresh();
    };

    const handleDelete = async () => {
        setShowSecretDeletionConfirmationDialog(false);
        try {
            await sendReq('DELETE');
        } catch (err) {
            setError(err.message);
        }

        refresh();
    };

    return (
        <Box>
            <GappedBox display="flex" flexDirection="row" justifyContent="space-between" row-gap="5%">
                <TextField label={title} value={value ? `${value}${'*'.repeat(32)}` : ''} disabled={true} fullWidth/>
                {value ?
                    <ButtonGroup alignSelf="flex-end" variant="text" size="small">
                        <Button color="secondary" onClick={() => setShowSecretRevocationConfirmationDialog(true)}>
                            <RefreshIcon fontSize="small"/>
                            Revoke
                        </Button>
                        <DeleteButton onClick={() => setShowSecretDeletionConfirmationDialog(true)}>
                            <DeleteIcon fontSize="small"/>
                            Delete
                        </DeleteButton>
                    </ButtonGroup>
                    :
                    <ButtonGroup alignSelf="flex-end" variant="text" size="small">
                        <Button color="secondary" onClick={handleRevoke}>
                            <AddIcon fontSize="small"/>
                            Create
                        </Button>
                    </ButtonGroup>
                }
            </GappedBox>
            <Dialog open={showSecretRevocationConfirmationDialog} onClose={() => setShowSecretRevocationConfirmationDialog(false)}>
                <DialogTitle>Revoke Secret?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>Do you want to revoke your secret?</p>
                        <p>Once it's revoked, a new secret will be generated and the current secret will be invalidated.</p>
                        <p>Further, the current secret cannot be re-created.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DeleteButton onClick={handleRevoke}>Revoke</DeleteButton>
                    <Button onClick={() => setShowSecretRevocationConfirmationDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={newSecretValue} onClose={() => setNewSecretValue(undefined)}>
                <DialogTitle>Secret Created</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>Your new secret is:</p>
                        <b><code>{newSecretValue}</code></b>
                        <p>Save it somewhere safe. It will <b><i>NOT</i></b> be shown again.</p>
                        <p>Lolo will authenticate requests before forwarding them to your devices.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewSecretValue(undefined)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showSecretDeletionConfirmationDialog} onClose={() => setShowSecretDeletionConfirmationDialog(false)}>
                <DialogTitle>Delete Secret?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <p>Do you want to delete your secret?</p>
                        <p>Once it's deleted, it <b><i>CANNOT</i></b> be re-created.</p>
                        <p>Further, if removed, Lolo will <b><i>NOT</i></b> authenticate requests before forwarding them to your devices.</p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
                    <Button onClick={() => setShowSecretDeletionConfirmationDialog(false)}>Close</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(undefined)}>
                <Alert severity='error'>{error}</Alert>
            </Snackbar>
        </Box>
    )
};

export default RevocableSecret;
