import themes from "../layout/themes";

const apiUrlMapping = {
  "pvpc.io":           "https://be.pvpc.io/uYsyopCuFi1xDWgtTLq44y",
  "dev.pvpc.io":       "https://be.dev.pvpc.io/api",
  "pvpc.lolo.company": "https://dev.pvpc.lolo.company/dc7bLsruuZ95cAgiEzvRQc",
  "eun.pvpc.io":       "https://be.pvpc.io/uYsyopCuFi1xDWgtTLq44y",
  "localhost":         "https://be.dev.pvpc.io/api",
  "eu-1-prod.pvpc.io": "https://be.eu-1-prod.unify.pvpc.io/api",
  "tokyo.pvpc.io":     "https://be.tokyo.pvpc.io/api",
  "us-1.pvpc.io":      "https://be.us-1.pvpc.io/api",
};

const themeMapping = {
  "localhost":         themes.default,
  "eun.pvpc.io":       themes.eun
};

export { apiUrlMapping, themeMapping };
