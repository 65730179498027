import React from "react";
import { LoloAdmin, LoloResource } from "@lolocompany/react-admin-lolo";
import { Resource } from "react-admin";
import { Route } from "react-router-dom";

import { Dashboard } from "./dashboard";
import RadTest from "./RadTest";
import ShowDevice from "./device/ShowDevice";

import EditGeofence from "./geofence/Edit";
import ListGeofences from './geofence/List';

import ListGateways from "./gateway/ListGateways";
import ListApns from "./apn/ListApns";

import Layout from "./layout";
import schemaTransforms from "./schema-transforms";
import { apiUrlMapping } from "./config/env";
import "./App.css";
import ListSmscGroups from "./smsc-group/ListSmscGroups";
import BillingReport from "./report/billing";

import AccountingReport from "./report/accounting";
import dataProvider from "./providers/dataProvider";
import { RevocableSecret } from "./widgets";

import ListApps from './apps/ListApps';
import EditApp from './apps/EditApp';

const App = () => {
  const baseUrl =
    process.env.REACT_APP_API_URL || apiUrlMapping[window.location.hostname];

  if (!baseUrl) {
    alert('URL mapping for "' + window.location.hostname + '" not found');
    return null;
  }

  return (
    <LoloAdmin
      apiUrl={baseUrl}
      accountsUrl={baseUrl + "/accounts/all"}
      dashboard={Dashboard}
      title="Lolo PVPC"
      layout={Layout}
      dataProvider={dataProvider(baseUrl)}
      customRoutes={[
        <Route exact path="/radtest" component={RadTest} />,
        <Route exact path="/report/billing" component={BillingReport} />,
      ]}
      widgets={{
        revocableSecret: RevocableSecret,
      }}
    >
      <LoloResource
        name="accounts"
        createSchemaTransform={schemaTransforms.account.create}
      />
      <LoloResource name="radius-clients" />
      <LoloResource name="csps" />
      <LoloResource
        name="vrfs"
        createSchemaTransform={schemaTransforms.vrf.create}
        editSchemaTransform={schemaTransforms.vrf.edit}
        listSchemaTransform={schemaTransforms.vrf.list}
      />
      <LoloResource
        name="apns"
        list={ListApns}
        listSchemaTransform={schemaTransforms.apn.list}
      />
      <LoloResource name="apn-networks" />
      <LoloResource
        name="subnets"
        edit={null}
        create={null}
        listSchemaTransform={schemaTransforms.subnet.list}
      />
      <LoloResource name="apn-groups" />
      <LoloResource name="device-groups" />
      <LoloResource
        name="forwardings"
        createSchemaTransform={schemaTransforms.forwarding.create}
        editSchemaTransform={schemaTransforms.forwarding.edit}
      />
      <LoloResource
        name="devices"
        show={ShowDevice}
        createSchemaTransform={schemaTransforms.device.create}
        listSchemaTransform={schemaTransforms.device.list}
      />
      <LoloResource name="device-ids" />
      <LoloResource name="dns-records" />
      <LoloResource name="acls" />
      <LoloResource name="aggregations" />
      <LoloResource name="automations" />
      <LoloResource
        name="geofences"
        edit={EditGeofence}
        create={EditGeofence}
        list={ListGeofences}
      />
      <LoloResource
        name="geofence-groups"
      />
      <LoloResource name="sessions" edit={null} create={null} />
      <LoloResource name="gateways" list={ListGateways} />
      <LoloResource name="vpn-tunnels" />
      <LoloResource name="ike-policy-templates" />
      <LoloResource name="ipsec-policy-templates" />
      <LoloResource name="egress-gateways" />
      <LoloResource
        name="smscs"
        listSchemaTransform={schemaTransforms.smsc.list}
      />
      <LoloResource name="smsc-groups" list={ListSmscGroups} />
      <LoloResource name="smsc-numbering-plans" create={null} />
      <Resource name="accounting" list={AccountingReport} />
      <Resource name="packet-captures" />
      <LoloResource
        name="apps"
        createSchemaTransform={schemaTransforms.app.create}
        edit={EditApp}
        list={ListApps}
      />
      <LoloResource
        name="deployments" 
        create={null}
        edit={null}
      />
    </LoloAdmin>
  );
};

export default App;
