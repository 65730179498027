import React from 'react';
import { Typography } from '@material-ui/core';
import * as ra from 'react-admin';
import LinearProgressBar from '../common/LinearProgressBar';
import { formatBytes } from '../dashboard/utils';

const Buckets = ({ id, buckets, aggsData }) => {
  const dataProvider = ra.useDataProvider();
  const refresh = ra.useRefresh();

  return Object.keys(buckets).length ?
    <>
      <Typography variant="h5">Buckets</Typography>
      {
        Object.entries(aggsData).map(([aggId, agg]) => {
          const bucket = buckets[aggId];
          const usedBytes = bucket ? bucket.bytes : 0;
          const maxBytes = agg.threshold;

          const onReset = async () => {
            await dataProvider.sendRequest(
              `/devices/${id}/buckets/${aggId}`, {
              method: 'delete'
            });

            refresh();
          };

          return (
            <React.Fragment key={aggId}>
              <LinearProgressBar
                variant="determinate"
                value={(Math.min(usedBytes / maxBytes * 100, 100))}
                title={agg.name}
                subtitle={`${formatBytes(usedBytes)} of ${formatBytes(maxBytes)}`}
                onReset={onReset}
              />
            </React.Fragment>
          )
        })
      }
    </>
    : null
}

export default Buckets;