import React, {useState, useEffect} from 'react'
import { useDataProvider } from 'react-admin';
import { Marker, useMap, useMapEvents, Popup } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useHistory } from "react-router-dom";
import 'react-leaflet-markercluster/dist/styles.min.css'

const MarkerClusterMap = () => {
  const map = useMap()
  const history = useHistory();
  const dataProvider = useDataProvider();
  const [boundingBox, setBoundingBox] = useState([[55.1282, 15.6435], [65.1282, 22.6435] ]) // bounds to set Sweden as default center
  const [mapMarkers, setMapMarkers] = useState([])

  const getGeoLocation = () => {
    if('geolocation' in window.navigator){
      window.navigator.geolocation.getCurrentPosition((pos) => {
        setBoundingBox([[pos.coords.latitude - 3, pos.coords.longitude - 3 ], [pos.coords.latitude + 3, pos.coords.longitude + 3 ]])
      }, (err) => {
        console.log(err.message)
      })
    }
  }

  useEffect(() => {
    if(map){
      map.fitBounds(boundingBox)
    }
    const query = [boundingBox[0][1], boundingBox[1][1], boundingBox[1][0], boundingBox[0][0]].join(',').toString()
    dataProvider.sendRequest(`/markers?bbox=${query}`)
      .then(res => {
        if(res.data.markers){
          setMapMarkers(res.data.markers)
        }
      });
  }, [boundingBox])

  useEffect(() => {
    getGeoLocation()
  }, [])

  useMapEvents({
    moveend: () => {
      const { _southWest, _northEast } = map.getBounds()
      setBoundingBox([[_southWest.lat, _southWest.lng], [_northEast.lat, _northEast.lng]])
    }
  });

  const key = String(Math.random());
  
  return (
    <MarkerClusterGroup key={key}>
      {
        mapMarkers.map((v) => (
          <Marker key={v.deviceId} position={[v.lat, v.lon]}>
            <Popup>
                <span style={{cursor:'pointer', textDecoration:'underline', color:'blue'}} 
                  onClick={() => history.push(`/devices/${v.deviceId}/show`)}>
                    View Device
                </span>
            </Popup>
          </Marker>
        ))
      }
    </MarkerClusterGroup>
  )
}

export default MarkerClusterMap