import React from 'react';
import Form from '@rjsf/material-ui';
import Grid from '@material-ui/core/Grid';
import * as ra from 'react-admin';

import Map from './EditMap';
import FormButtons from '../FormButtons';

const EditGeofence = props => {
  const { id } = props;

  const dataProvider = ra.useDataProvider();
  const [ resource, setResource ] = React.useState();
  const [ geoJson, setGeoJson ] = React.useState();
  const [ groups, setGroups ] = React.useState([]);

  React.useEffect(() => {
    dataProvider.sendRequest(`/geofence-groups`).then(
      res => {
        setGroups(res.data.geofenceGroups);
      }
    );
  }, [dataProvider]);

  React.useEffect(() => {
    if (id) {
      dataProvider.sendRequest(`/geofences/${id}`).then(
        res => {
          setResource(res.data);
          setGeoJson(res.data.geoJson);
        }
      );

    } else {
      setResource(newGeofence());
      setGeoJson([]);
    }
  }, [ dataProvider, id ]);

  const handleSubmit = async () => {
    const url = resource.id ? `/geofences/${id}` : '/geofences';
    const method = resource.id ? 'put' : 'post';
    const body = JSON.stringify({ ...resource, geoJson });

    await dataProvider.sendRequest(url, { method, body });
    props.history.push('/geofences');
  };

  const schema = {
    properties: {
      name: { type: 'string', title: 'Name' },
      description: { type: 'string', title: 'Description' },
      geofenceGroupId: {
        type: 'string',
        title: 'Group',
        enum: [ '', ...groups.map(it => it.id) ],
        enumNames: [ '-', ...groups.map(it => it.name) ]
      },
      colorHex: { type: 'string', title: 'Color' },
    },
    required: [ 'name' ]
  };

  return geoJson ? (
    <>
      <EditActions {...props}/>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Map
            zoom={resource.viewSettings.zoom}
            center={resource.viewSettings.center}
            setZoomPan={viewSettings => setResource(resource => ({
              ...resource,
              viewSettings
            }))}
            geoJson={geoJson}
            setGeoJson={geoJson => {
              setGeoJson(geoJson)
            }}
            colorHex={resource.colorHex}
          />
        </Grid>
        <Grid item xs={5}>
          <Form
            formData={resource}
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            onChange={({ formData }) => setResource({
              ...resource,
              ...formData
            })}>
            <FormButtons history={props.history} />
          </Form>
        </Grid>
      </Grid>
    </>
  ) : <p>Loading...</p>;
};

const uiSchema = {
  colorHex: {
    'ui:widget': 'color'
  }
};

const newGeofence = () => ({
  colorHex: '#3388FF',
  viewSettings: {
    center: [
      34, 19
    ],
    zoom: 2
  }
});

const EditActions = ({ basePath, resource }) => (
  <ra.TopToolbar>
    <ra.ListButton basePath={basePath} resource={resource} />
  </ra.TopToolbar>
);

export default EditGeofence;