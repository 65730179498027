import account from './account';
import device from './device';
import subnet from './subnet';
import apn from './apn';
import smsc from './smsc';
import forwarding from './forwarding'
import vrf from './vrf';
import app from './app';

export default {
	account,
	device,
	subnet,
	apn,
	forwarding,
	smsc,
	vrf,
	app
}

