import React from 'react'
import {defaultTheme} from 'react-admin'
import { createTheme } from '@material-ui/core/styles'
import merge from 'lodash/merge';
import { themeMapping } from '../config/env'
import themes from '../layout/themes'

const GlobalDataContext = React.createContext({})

function useGlobalContext () {
  const context = React.useContext(GlobalDataContext)
  if(!context) {
    throw new Error('useGlobalContext must be used within GlobalContext');
  }

  return context
}

function GlobalContext ({children}) {
  const selectedTheme = createTheme(merge({}, defaultTheme, themeMapping[window.location.hostname] || themes.default))

  return (
    <GlobalDataContext.Provider value={{
      selectedTheme,
    }}>
      {children}
    </GlobalDataContext.Provider>
  )
}

export {
  GlobalContext,
  useGlobalContext
}