import React from 'react';

import { LoloList } from '@lolocompany/react-admin-lolo';
import { Tabs, Tab } from '@material-ui/core';
import Map from './ListMap'

import * as ra from 'react-admin';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

const ListGeofences = props => {
  const [tab, setTab] = React.useState(0);

  return (
    <LoloList {...props}>
      <>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={((ev, i) => setTab(i))}
          aria-label="disabled tabs"
          >
          <Tab label="List" />
          <Tab label="Map" />
        </Tabs>
        <TabPanel value={tab} index={0}>
          <ra.Datagrid
            rowClick={'edit'}
            expand={props.expand || <ExpandPanel />}
            bulkActionButtons={<BulkActionButtons />}
            hasBulkActions={true}
          >
            <ColorDot />
            <ra.TextField source='name' />
            <ra.TextField source='description' />
            <ra.DateField source='createdAt' showTime={true}/>
          </ra.Datagrid>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <Map />
        </TabPanel>
      </>
    </LoloList>
  );
}

const ColorDot = ({ record }) => (
  <ra.FunctionField label={false} render={geofence => (
    <div style={{
      backgroundColor: record.colorHex,
      borderRadius: 10,
      width: 20,
      height: 20
    }}/>
  )}/>
);

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {
        value === index ? 
        children : 
        null
      }
    </div>
  );
}

const ExpandPanel = ({ id, record, resource }) => (
  <pre style={{ fontSize: '1.1rem' }}>{JSON.stringify(record, null, 2)}</pre>
);

const BulkActionButtons = props => (
  <ra.BulkDeleteButton {...props} />
);

export default ListGeofences
