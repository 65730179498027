import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import themes from './layout/themes'
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GlobalContext, useGlobalContext } from './hooks/globalContext';

/* Workaround for unfortunate pluralization of "VRF" */

import * as inflection from 'inflection';
const oldPluralize = inflection.pluralize;

inflection.pluralize = (str, plural) => {
  if (/^vrf/i.test(str)) return str + 's';
  return oldPluralize.call(inflection, str, plural);
};

const MainApp = () => {
  const { selectedTheme } = useGlobalContext()

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalContext>
      <MainApp />
    </GlobalContext>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
